(function () {
    'use strict';
    angular
        .module('mySkillsPersonalApp')
        .directive('atributoGenerico', atributoGenerico);

    atributoGenerico.$inject = ['AtributoGenerico', '$timeout', '$uibModal'];

    function atributoGenerico(AtributoGenerico, $timeout, $uibModal) {
        var directive = {
            restrict: 'E',
            scope: {
                tipoVinculo: '=',
                vinculo: '=',
                readonly: '=',
                showDatails: '=?',
                hideMultiples: '=?',
                recarregar: '=?',
                mostraAtributosNulos: '=?',
                atributos: '=',
            },
            templateUrl: 'app/components/atributo-generico/atributo-generico.html',
            link: link,
        };
        return directive;

        function link(scope, element, attrs) {

            scope.addAtributoMultiplo = addAtributoMultiplo;
            scope.gravarAudio = gravarAudio;
            scope.playAudio = playAudio;
            scope.gravarVideo = gravarVideo;
            scope.playVideo = playVideo;
            scope.mostraAtributosNulos = scope.mostraAtributosNulos !== undefined ? scope.mostraAtributosNulos : false;
            scope.showDatails = scope.showDatails !== undefined ? scope.showDatails : true;
            scope.hideMultiples = scope.hideMultiples !== undefined ? scope.hideMultiples : false;

            function carregarAtributosGenericos() {
                AtributoGenerico.query({tipoAtributo: scope.tipoVinculo}, function (data) {
                    scope.atributos = data;
                    if (scope.vinculo) {
                        AtributoGenerico.getVinculos({
                            tipoVinculo: scope.tipoVinculo,
                            vinculo: scope.vinculo
                        }, function (data) {
                            for (var i = 0; i < data.length; i++) {
                                var vinculo = data[i];
                                for (var j = 0; j < scope.atributos.length; j++) {
                                    var atributo = scope.atributos[j];
                                    if (vinculo.atributo && atributo.id === vinculo.atributo.id) {
                                        atributo.audio = vinculo.audio;
                                        atributo.valor = atributo.tipoCampo === 'MULTIPLO' ? vinculo.valor.split(',') : vinculo.valor;
                                    }
                                }
                            }
                        });
                    }
                });
            }

            function playVideo(video) {
                $uibModal.open({
                    templateUrl: 'app/entities/video/video-detail.html',
                    controller: 'VideoDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        video: function () {
                            return video;
                        }
                    }
                }).result.then(function (result) {

                }, function () {

                });
            }

            function gravarVideo(atributo) {
                $uibModal.open({
                    templateUrl: 'app/entities/video/video-edit.html',
                    controller: 'VideoController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                }).result.then(function (result) {
                    atributo.valor = result.data.conteudoFormatado;;
                }, function () {
                    console.log("VORTOO >>>");
                });
            }

            function playAudio(audio) {

                $uibModal.open({
                    templateUrl: 'app/entities/audio/audio-detail.html',
                    controller: 'AudioDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        audio: function () {
                            return audio;
                        }
                    }
                }).result.then(function (result) {

                }, function () {

                });

            }

            function gravarAudio(atributo, campo) {
                $uibModal.open({
                    templateUrl: 'app/entities/audio/audio-edit.html',
                    controller: 'AudioController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                }).result.then(function (result) {
                    atributo[campo] = result;
                }, function () {
                });
            }

            function addAtributoMultiplo(item, atributo) {
                if (!atributo.valor) {
                    atributo.valor = [];
                }
                if (atributo.valor.indexOf(item) >= 0) {
                    atributo.valor.splice(atributo.valor.indexOf(item), 1);
                } else {
                    atributo.valor.push(item);
                }
            }

            scope.$watch('recarregar', function (newValue, oldValue) {
                carregarAtributosGenericos();
            }, true);

            $timeout(function () {
                carregarAtributosGenericos();
            }, 500);
        }
    }
}());
