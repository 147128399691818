(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('CursoDetailController', CursoDetailController);

    CursoDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Curso', '$sce', 'Principal', 'Pessoa', 'ConfirmService'];

    function CursoDetailController($scope, $rootScope, $stateParams, previousState, entity, Curso, $sce, Principal, Pessoa, ConfirmService) {
        var vm = this;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.curso = entity;
        vm.trustedHtml = trustedHtml;
        vm.removerParticipacao = removerParticipacao;
        vm.participar = participar;
        vm.previousState = previousState.name;

        function verificarParticipacao() {
            Curso.participando({id: vm.curso.id}, function (data) {
                vm.curso.participando = data && data.id;
            });
        }

        Pessoa.getPerfilByUser({}, function (pessoa) {
            vm.pessoa = pessoa;
            verificarParticipacao();
        });

        var unsubscribe = $rootScope.$on('mySkillsPersonalApp:cursoUpdate', function (event, result) {
            vm.curso = result;
        });

        $scope.$on('$destroy', unsubscribe);


        function participar() {
            ConfirmService.confirm({
                    title: "Confirme a Operação!",
                    text: "Ao continuar você estará de participando desse curso.",
                    confirm: function () {

                        Curso.participar({curso: vm.curso, user: {id: vm.pessoa.userId}}, function (data) {
                            ConfirmService.swal({title: "Pronto!", text: "O seu registro foi feito com sucesso!"}, verificarParticipacao);
                        });
                    },
                    cancel: function () {
                    }
                }
            );

        }

        function removerParticipacao() {
            ConfirmService.confirm({
                    title: "Confirme a Operação!",
                    text: "Ao continuar você deixará de participar desse curso.",
                    confirm: function () {
                        Curso.removerParticipacao({id: vm.curso.id}, function () {
                            ConfirmService.swal({title: "Pronto!", text: "O seu registro foi removido com sucesso!"}, verificarParticipacao);
                        });
                    },
                    cancel: function () {
                    }
                }
            );

        }

        function trustedHtml(html) {
            return $sce.trustAsHtml(html);
        }

    }
})();
