(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('EmpregadorController', EmpregadorController);

    EmpregadorController.$inject = ['$scope', '$state', '$timeout', 'Empregador', 'EmpregadorSearch',
        'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Principal', 'Pessoa', 'ConfirmService', '$location', 'Parceiro'];

    function EmpregadorController($scope, $state, $timeout, Empregador, EmpregadorSearch,
        ParseLinks, AlertService, pagingParams, paginationConstants, Principal, Pessoa, ConfirmService, $location, Parceiro) {
        var vm = this;
        vm.maxResult = 48;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page ? pagingParams.page : 1;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.cleanAndLoadAll = cleanAndLoadAll;
        vm.loadMore = loadMore;
        vm.carregarNoMapa = carregarNoMapa;
        vm.glCenter = {lat: -23.4248724, lng: -51.9265809};
        vm.glControls = {
            geolocate: {
                enabled: true,
                options: {
                    position: 'top-left'
                }
            }
        };

        var host = $location.host();
        vm.subdomain = host.split('.')[0];

        function carregarParceiro() {
            Parceiro.getPublico({identificador: vm.subdomain}, function (data) {
                vm.parceiro = data;
                vm.idParceiro = vm.parceiro.id;
                vm.filtros.parceiroId = vm.parceiro.id;
                loadAll();
            }, function () {
                loadAll();
            });
        }


        $timeout(function () {
            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            };
        });

        vm.filtros = {
            page: vm.page - 1,
            pageSize: pagingParams.pageSize ? pagingParams.pageSize : vm.maxResult,
            atributos: [],
            nome: pagingParams.nome ? pagingParams.nome : null,
            parceiroId: vm.idParceiro ? vm.idParceiro : null,
            estado: pagingParams.estado ? pagingParams.estado : null,
            bairro: pagingParams.bairro ? pagingParams.bairro : null,
            nacionalidade: pagingParams.nacionalidade ? pagingParams.nacionalidade : null,
            quantidadeFuncionario: pagingParams.quantidadeFuncionario ? pagingParams.quantidadeFuncionario : null,
        };

        function carregarCidadeDoUsuarioParaFiltrar() {
            Principal.identity().then(function (account) {
                vm.isAuthenticated = Principal.isAuthenticated();
                carregarParceiro();
            });
        }

        carregarCidadeDoUsuarioParaFiltrar();

        vm.recarregarAtributos = 0;
        vm.atributos = [];
        vm.qntFuncionarios = [
            {codigo: 0, value: 'ENTRE_1_10', descricao: 'Entre 1 e 10'},
            {codigo: 0, value: 'ENTRE_11_100', descricao: 'Entre 11 e 100'},
            {codigo: 0, value: 'ENTRE_101_500', descricao: 'Entre 101 e 500'},
            {codigo: 0, value: 'ENTRE_501_1000', descricao: 'Entre 501 e 1000'},
            {codigo: 0, value: 'MAIS_1000', descricao: '1000 ou mais'},
        ];
        vm.empregadors = [];


        function loadMore(page) {
            vm.page = page;
            console.log("PAGEEEE > ", page);
            vm.filtros.page = vm.page - 1;
            transition();
        }

        function cleanAndLoadAll() {
            clear();
            vm.filtros.page = 0;
            vm.page = 1;
            vm.filtros.pageSize = vm.maxResult;
            vm.filtros = {
                page: 0,
                pageSize: pagingParams.pageSize ? pagingParams.pageSize : vm.maxResult,
                atributos: [],
                nome: null,
                cidade: null,
                estado: null,
                bairro: null,
                nacionalidade: null,
                quantidadeFuncionario: null,
                parceiroId: vm.idParceiro ? vm.idParceiro : null,
            };
            vm.empregadors = [];

            loadAll();
        }

        function loadAll() {
            vm.carregando = true;
            vm.filtros.atributos = {};
            var agrupador = {vinculo: 123, tipo: 'EMPREGADOR', atributos: []};
            for (var i = 0; i < vm.atributos.length; i++) {
                var atributo = vm.atributos[i];
                if (atributo && atributo.valor && atributo.valor !== "" && atributo.valor.length > 0) {
                    agrupador.atributos.push({
                        atributo: atributo,
                        tipoVinculo: agrupador.tipo,
                        vinculo: agrupador.vinculo,
                        valor: atributo.tipoCampo === 'MULTIPLO' ? atributo.valor.join(',') : atributo.valor
                    });
                }
            }
            if (agrupador.atributos) {
                vm.filtros.atributos = agrupador;
            }

            Empregador.filtrar(vm.filtros, onSuccess, onError);


            function onSuccess(data, headers) {
                vm.empregadors = data;
                vm.recarregarAtributos++;
                vm.carregando = false;
                vm.page = vm.filtros.page + 1;
                vm.totalItems = headers('X-Total-Count');
                if (vm.empregadors.length === 0) {

                    ConfirmService.confirm({
                            title: "Ops ...",
                            text: "Infelizmente as oportunidades da nossa base não correspondem com os filtros que você buscou, o que acha de olhar em toda nossa base? ",
                            confirm: function () {
                                vm.cleanAndLoadAll();
                                vm.filtrar = false;
                            },
                            cancel: function () {
                                vm.cleanAndLoadAll();
                                vm.filtrar = false;
                            }
                        }
                    );
                } else {
                    carregarNoMapa();
                }

                Empregador.count(vm.filtros, function (data) {
                    vm.totais = data;
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
                vm.carregando = false;
            }
        }


        function carregarNoMapa() {
            vm.glMarkers = [];
            for (var i = 0; i < vm.empregadors.length; i++) {
                var empregador = vm.empregadors[i];

                if (empregador.coordinates) {
                    var a = document.createElement('a');
                    a.href = "#/home/empregador/" + empregador.id;
                    a.target = "_blank";
                    a.title = empregador.nomeFantasia;

                    var element = document.createElement('div');
                    element.style.background = 'url(' + empregador.logo + ')  no-repeat';
                    element.style.backgroundSize = 'contain';
                    element.style.backgroundPosition = 'center';
                    element.style.width = 50 + 'px';
                    element.style.height = 50 + 'px';
                    element.style.borderRadius = '10%';
                    a.appendChild(element);

                    vm.glMarkers.push({
                        coordinates: empregador.coordinates,
                        element: a,
                    });

                }


            }
        }


        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page ? vm.page : 1,
                pageSize: vm.filtros.pageSize ? vm.filtros.pageSize : 24,
                nome: vm.filtros.nome ? vm.filtros.nome : null,
                cidade: vm.filtros.cidade ? vm.filtros.cidade : null,
                estado: vm.filtros.estado ? vm.filtros.estado : null,
                bairro: vm.filtros.bairro ? vm.filtros.bairro : null,
                nacionalidade: vm.filtros.nacionalidade ? vm.filtros.nacionalidade : null,
                quantidadeFuncionario: vm.filtros.quantidadeFuncionario ? vm.filtros.quantidadeFuncionario : null,
            });
        }


        function clear() {
            vm.filtros = {atributos: []};
            for (var i = 0; i < vm.atributos.length; i++) {
                var atributo = vm.atributos[i];
                if (atributo && atributo.valor && atributo.valor !== "" && atributo.valor.length > 0) {
                    atributo.valor = "";
                }
            }
        }

    }
})();
