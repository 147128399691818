(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('VagaDialogController', VagaDialogController);

    VagaDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams', 'entity', 'Empregador',
        'Vaga', 'ConfirmService', 'Pessoa', 'Questionario', 'Principal', '$location', 'pagingParams'];

    function VagaDialogController($timeout, $scope, $state, $stateParams, entity, Empregador,
                                  Vaga, ConfirmService, Pessoa, Questionario, Principal, $location, pagingParams) {
        var vm = this;

        vm.vaga = entity;
        vm.clear = clear;
        vm.getImageVaga = getImageVaga;
        vm.irParaProduto = irParaProduto;
        vm.desmarcarVaga = desmarcarVaga;
        vm.candidatarSe = candidatarSe;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.localidades = [];
        vm.resultados = [];
        vm.localUrl = $location.absUrl();
        vm.vagasUsuario = Vaga.getVagasUsuario();
        vm.mostraEmpresa = pagingParams.empregador == 'false';

        function getAccount() {
            if (vm.isAuthenticated()) {
                Pessoa.getPerfilByUser({}, findByLoginSuccess);

                function findByLoginSuccess(pessoa) {
                    vm.pessoa = pessoa;
                    vm.pessoa.nascimento_tela = pessoa.nascimento;
                    buscarPercentualCadastro(vm.pessoa.pessoaId);
                    buscarResuladosDaAvaliacao();
                    Pessoa.getLocalidades({id: vm.pessoa.pessoaId}, function (localidades) {
                        vm.localidades = localidades;
                    });
                    Vaga.getVagasUsuario({}, function (data2) {
                        vm.vagasUsuario = data2;
                        for (var j = 0; j < vm.vagasUsuario.length; j++) {
                            var vagaUsuario = vm.vagasUsuario[j];
                            if (vm.vaga.id === vagaUsuario.id) {
                                vm.vaga.participando = true;
                            }
                        }
                    });
                };
            }
        }


        getAccount();

        function desmarcarVaga(vagaId) {
            ConfirmService.swal({
                    title: "Atenção!",
                    text: "Ao desmarcar essa profissão você será removido da base de dados de todas as empresas que a oferecem.",
                    type: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#f9b36d",
                    confirmButtonText: "Revomer PROFISSÃO",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        Vaga.deleteVagaUsuarioById({id: vagaId});
                        $state.go('vaga', {}, {reload: true});
                    }
                }
            );
        }

        function getImageVaga(vagaSetor) {
            return Vaga.getImageVaga(vagaSetor);
        }

        function buscarResuladosDaAvaliacao() {
            if (vm.vaga.avaliacoes && vm.vaga.avaliacoes.length > 0) {
                for (var i = 0; i < vm.vaga.avaliacoes.length; i++) {
                    var avaliacao = vm.vaga.avaliacoes[i];
                    Questionario.getResultadoInternoCorporateAvaliacaoByMontagemId({id: avaliacao.montagemId}, function (data) {
                        if (data && data.length > 0) {
                            vm.resultados.concat(data);
                            for (var j = 0; j < data.length; j++) {
                                marcaComoRealizada(data[j].montagem_id);
                            }
                        }
                    }, function (error) {
                        console.log(error);
                    });
                }
            }
        }


        function marcaComoRealizada(montagemId) {
            for (var i = 0; i < vm.vaga.avaliacoes.length; i++) {
                if (vm.vaga.avaliacoes[i].montagemId == montagemId) {
                    vm.vaga.avaliacoes[i].realizada = true;
                }
            }
        }

        function buscarPercentualCadastro(id) {
            Pessoa.getPercenutalConcluido({id: id}, findByPercentualCadastroSuccess);
        }

        function findByPercentualCadastroSuccess(percentualCadastro) {
            vm.percentualCadastro = percentualCadastro;
        }


        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            //$uibModalInstance.dismiss('cancel');
            $state.go('vaga');
        }

        function irParaProduto() {
            $state.go('produto');
            // $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess(result) {
            $state.go('vaga-usuario');
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function alertaCompletarCadastro() {
            ConfirmService.swal({
                    title: "Olá, que bom que deseja marcar essa profissão!",
                    text: "Contudo é necessário completar seu perfil MySkills, deixando seu perfil bem elaborado, poderá marcar essa e outras profissões.",
                    type: "",
                    showCancelButton: true,
                    confirmButtonColor: "#1acfb0",
                    confirmButtonText: "Complementar o Perfil Agora",
                    cancelButtonText: "Agora Não",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $state.go("home");
                        clear();
                    }
                });
        }

        function alertaFazerAvaliacoes() {
            ConfirmService.swal({
                    title: "Atenção",
                    text: "Você ainda não realizou as avaliações necessárias para se candidatar. Revise os Atributo(s) Qualificador(es)",
                    type: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#1acfb0",
                    confirmButtonText: "Realizar Avaliações",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $state.go("produto");
                        clear();
                    }
                });
        }

        function cadastroRealizadoComSucesso() {
            ConfirmService.swal({
                    title: "Parabéns!",
                    type: "success",
                    text: "Agora você está participando dessa profissão. As empresas que a disponibilizam já foram informadas do seu interesse!",
                    showCancelButton: false,
                    confirmButtonColor: "#18a689", confirmButtonText: "OK",
                },
                function () {
                    Vaga.candidatarSe(vm.vaga, onSaveSuccess, onSaveError);
                });
        }

        function candidatarSe(vaga) {
            vm.vaga.localidades = vm.localidades;
            $timeout(function () {
                cadastroRealizadoComSucesso();
            }, 500);

        }

        function buscarEmpresasPorVaga() {
            vm.filtros = {
                page: 0,
                pageSize: 99,
                vagaId: vm.vaga.id
            };
            if (vm.mostraEmpresa) {
                Empregador.filtrar(vm.filtros, onSuccess, onError);
            }
            function onSuccess(data, headers) {
                vm.empregadors = data;
            }

            function onError(error) {
                vm.carregando = false;
            }
        }

        buscarEmpresasPorVaga();
    }
})();
