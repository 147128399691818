(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PerfilListController', PerfilListController);

    PerfilListController.$inject = ['$rootScope', '$scope', '$uibModal', 'Principal', 'LoginService', '$state', 'Pessoa',
        'BuscaCep', 'Upload', '$window', 'FormacaoAcademica', 'ExperienciaProfissional', 'DocumentoPessoal', 'AtributoGenerico', '$http', '$localStorage'];

    function PerfilListController($rootScope, $scope, $uibModal, Principal, LoginService, $state, Pessoa,
        BuscaCep, Upload, $window, FormacaoAcademica, ExperienciaProfissional, DocumentoPessoal, AtributoGenerico, $http, $localStorage) {
        var vm = this;

        vm.baixarAnexo = baixarAnexo;
        vm.baixarCurriculo = baixarCurriculo;
        vm.openAudio = openAudio;

        vm.activeTab = $localStorage.activeTab || 0;

        vm.playAudioPerfil = playAudioPerfil;
        vm.gravarAudioPerfil = gravarAudioPerfil;
        vm.deleteAudioPerfil = deleteAudioPerfil;

        vm.openVideo = openVideo;
        vm.playVideo = playVideo;
        vm.deleteVideo = deleteVideo;

        vm.openAudioFormacao = openAudioFormacao;
        vm.deleteAudioFormacao = deleteAudioFormacao;
        vm.playAudioFormacao = playAudioFormacao;
        vm.openAudioExperiencia = openAudioExperiencia;
        vm.deleteAudioExperiencia = deleteAudioExperiencia;
        vm.playAudioExperiencia = playAudioExperiencia;
        vm.playAudio = playAudio;
        vm.labels = [];
        vm.series = ['Series A'];

        vm.carregarEstados = carregarEstados;
        vm.carregarCidades = carregarCidades;
        vm.adicionarLocalidade = adicionarLocalidade;
        vm.removerLocalidade = removerLocalidade;
        vm.salvarDadosPessoais = salvarDadosPessoais;
        vm.salvarAtributosGenericos = salvarAtributosGenericos;
        vm.goToTab = goToTab;

        vm.tipoAtributosDadosPessoais = [
            {tipo: 'DADOS_PESSOAIS', descricao: 'Sobre Você', atributos: []},
            {tipo: 'DADOS_PESSOAIS_FAMILIA', descricao: 'Sobre seus Pais', atributos: []},
            {tipo: 'DADOS_PESSOAIS_CONJUGE', descricao: 'Sobre seu Cônjuge', atributos: []},
            {tipo: 'DADOS_PESSOAIS_FILHOS', descricao: 'Sobre seus Filhos', atributos: []},
        ];

        vm.pcds = [{tipo: 'PCD', descricao: 'Pessoa com Deficiência', atributos: []}];


        vm.tiposAudio = [
            {campo: 'audio', descricao: 'Conte um pouco sobre você!'},
            {campo: 'audioEconomia', descricao: 'Qual sua visão sobre a economia atual do país?'},
            {campo: 'audioEducacao', descricao: 'Qual sua visão sobre os meios de educação do país?'},
            {campo: 'audioHobbies', descricao: 'Qual são seus hobbies e diversão, o que gosta de fazer?'},
            {
                campo: 'audioCapacitacaoAtual',
                descricao: 'O que faz atualmente em relação aos estudos e capacitação profissional?'
            },
            {
                campo: 'audioHorariosTrabalho',
                descricao: 'Qual sua preferência de horários para trabalhar? dia, noite, feriados? Explique.'
            },
            {campo: 'audioGerarResultados', descricao: 'Como você pode gerar resultados para as empresas?'},
            {
                campo: 'audioLivreRecrutador',
                descricao: 'Livre, fale o que acredita ser relevante para os recrutadores e empresários?'
            },
        ];

        vm.tiposVideo = [
            {campo: 'video', descricao: 'Tema Livre'},
            {campo: 'videoFamiliar', descricao: 'Sua estrutura familiar '},
            {campo: 'videoHabilidades', descricao: 'Competências e Habilidades'},
            {campo: 'videoDisponibilidade', descricao: 'Seus interesses e disponibilidades'},
            {campo: 'videoLideranca', descricao: ' Sua capacidade de liderar pessoas'},
        ];

        vm.data = [
            []
        ];

        $rootScope.$on('mySkillsPersonalApp:pessoaUpdate', function (event, resp) {
            getAccount();
        });




        getAccount();

        function getAccount() {
            Pessoa.getPerfilByUser({}, findByLoginSuccess);

            function findByLoginSuccess(pessoa) {
                vm.pessoa = pessoa;
                vm.pessoa.nascimento_tela = pessoa.nascimento;
                carregarTudo();
            };

        }

        function goToTab() {
            $localStorage.activeTab = vm.activeTab;
            carregarPorTabSelecionado();
        }

        function carregarPorTabSelecionado() {
            if (vm.activeTab == 0) {
                Pessoa.getContato({}, function (contato) {
                    vm.contato = contato;
                }, function (error) {
                    console.log(error);
                });
            }


            if (vm.activeTab == 5 || vm.activeTab == 6) {
                FormacaoAcademica.getAllByUserId({id: vm.pessoa.userId}, function (formacoes) {
                    // vm.formacoes = formacoes;
                    vm.cursos = [];
                    vm.formacoes = [];
                    if (formacoes) {
                        angular.forEach(formacoes, function (item) {
                            if (item.curso && item.curso.tipo == "CURSO") {
                                vm.cursos.push(item);
                            } else {
                                vm.formacoes.push(item);
                            }
                        });
                    }
                });
            }

            if (vm.activeTab == 8) {
                DocumentoPessoal.getAllByUserId({id: vm.pessoa.userId}, function (documentos) {
                    vm.documentos = documentos;
                });
            }


            if (vm.activeTab == 4) {
                ExperienciaProfissional.getAllByUserId({id: vm.pessoa.userId}, function (experiencias) {
                    vm.experiencias = experiencias;
                    angular.forEach(vm.experiencias, function (experiencia) {
                        vm.labels.push(experiencia.empregador.nomeFantasia);
                        var timeDiff = Math.abs(new Date(experiencia.inicio).getTime() - (experiencia.fim ? new Date(experiencia.fim).getTime() : new Date()));
                        vm.data[0].push(Math.ceil(timeDiff / (1000 * 3600 * 24) / 30));

                    });
                });
            }

            if (vm.activeTab == 2) {

                vm.totalAudios = 0;
                for (var i = 0; i < vm.tiposAudio.length; i++) {
                    var tipo = vm.tiposAudio[i];
                    if (vm.pessoa[tipo.campo]) {
                        vm.totalAudios++;
                    }
                }
            }

            if (vm.activeTab == 3) {
                vm.totalVideos = 0;
                for (var i = 0; i < vm.tiposVideo.length; i++) {
                    var tipo = vm.tiposVideo[i];
                    if (vm.pessoa[tipo.campo]) {
                        vm.totalVideos++;
                    }
                }
            }
        }

        function carregarTudo() {
            carregarPorTabSelecionado();
            Pessoa.getLocalidades({id: vm.pessoa.pessoaId}, function (localidades) {
                vm.localidades = localidades;
            });
            buscarPercentualCadastro(vm.pessoa.pessoaId);
        }


        function buscarPercentualCadastro(id) {
            Pessoa.getPercenutalConcluido({id: id}, findByPercentualCadastroSuccess);
        }

        function findByPercentualCadastroSuccess(percentualCadastro) {
            vm.percentualCadastro = percentualCadastro;
        }


        function baixarAnexo(arquivoId) {
            Pessoa.baixarAnexo(arquivoId);
        }

        function baixarCurriculo() {


            Pessoa.getCurriculo({id: vm.pessoa.pessoaId}, function (data) {
                /*  console.log(data);
                  var newWindow = window.open("", "mywindow", "menubar=1,resizable=1,width=500,height=500");
                  newWindow.document.write(data);*/

                console.log("data ", data);
                vm.arquivo = data;

                var fileName = vm.arquivo.nome;
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                var file = getBlobFromBase64(vm.arquivo);
                var fileURL = window.URL.createObjectURL(file);
                a.href = fileURL;
                a.download = fileName + ".pdf";
                a.click();

            });
        }

        function playAudio(audio) {

            $uibModal.open({
                templateUrl: 'app/entities/audio/audio-detail.html',
                controller: 'AudioDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    audio: function () {
                        return audio;
                    }
                }
            }).result.then(function (result) {

            }, function () {

            });

        }

        function playVideo(tipo) {
            $uibModal.open({
                templateUrl: 'app/entities/video/video-detail.html',
                controller: 'VideoDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    video: function () {
                        return vm.pessoa[tipo];
                    }
                }
            }).result.then(function (result) {

            }, function () {

            });
        }

        function playAudioFormacao(formacao) {
            playAudio(formacao.audio);
        }

        function playAudioExperiencia(experiencia) {
            playAudio(experiencia.audioApresentacao);
        }

        function getBlobFromBase64(arquivo) {
            /*data:{{vm.arquivo.contentType}};base64,{{vm.arquivo.bytes}}*/
            var byteCharacters = atob(arquivo.bytes);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {type: 'application/pdf'});
            return blob;
        }


        function deleteAudioExperiencia(experiencia) {
            experiencia.audioApresentacao = null;
            ExperienciaProfissional.update(experiencia);
        }

        function deleteVideo(tipo) {
            vm.pessoa[tipo] = null;
            Pessoa.updatePerfil(vm.pessoa, carregarTudo);
        }

        function deleteAudioFormacao(formacao) {
            formacao.audio = null;
            FormacaoAcademica.addAudio(formacao);
        }

        function openAudioFormacao(formacao) {
            vm.formacaoAcademica = formacao;
            openAudio(onSuccessAudioFormacao);
        }

        function openAudioExperiencia(experiencia) {
            vm.experienciaProfissional = experiencia;
            openAudio(onSuccessAudioExperiencia);
        }

        function gravarAudioPerfil(campo) {
            function onSucessAudioPerfil(result) {
                vm.pessoa[campo] = result;
                Pessoa.updatePerfil(vm.pessoa, carregarTudo);
            }

            openAudio(onSucessAudioPerfil);
        }

        function deleteAudioPerfil(campo) {
            vm.pessoa[campo] = null;
            Pessoa.updatePerfil(vm.pessoa, carregarTudo);
        }

        function playAudioPerfil(campo) {
            playAudio(vm.pessoa[campo]);
        }


        function onSuccessAudioFormacao(result) {
            vm.formacaoAcademica.audio = result;
            FormacaoAcademica.addAudio(vm.formacaoAcademica);
        }

        function onSuccessAudioExperiencia(result) {
            vm.experienciaProfissional.audioApresentacao = result;
            ExperienciaProfissional.addAudio(vm.experienciaProfissional);
        }

        function openAudio(callback) {
            $uibModal.open({
                templateUrl: 'app/entities/audio/audio-edit.html',
                controller: 'AudioController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
            }).result.then(function (result) {
                callback(result);
            }, function () {
                console.log("VORTOO >>>");
            });
        }

        function openVideo(tipo) {
            $uibModal.open({
                templateUrl: 'app/entities/video/video-edit.html',
                controller: 'VideoController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
            }).result.then(function (result) {
                vm.pessoa[tipo] = result.data.conteudoFormatado;
                Pessoa.updatePerfil(vm.pessoa, carregarTudo);
            }, function () {
                console.log("VORTOO >>>");
            });
        }

        function carregarPaises() {
            return $http({
                url: '/api/todos-paises',
                method: 'GET'
            }).then(function (data) {
                vm.paises = data.data;
                for (var i = 0; i < vm.paises.length; i++) {
                    var pais = vm.paises[i];
                    if (pais.codigo == 'BRA') {
                        vm.brasil = pais;
                        vm.localidade = {pais: vm.brasil};
                        carregarEstados();
                        break;
                    }
                }
            });
        }

        carregarPaises();

        function carregarEstados() {
            return $http({
                url: '/api/todos-estados',
                method: 'GET'
            }).then(function (data) {
                vm.estados = data.data;
            });
        }

        function carregarCidades() {
            return $http({
                url: '/api/todas-cidades',
                method: 'GET',
                params: {parameter: vm.localidade.estado.sigla}
            }).then(function (data) {
                vm.cidades = data.data;
            });
        }

        function adicionarLocalidade() {
            if (!vm.localidades) {
                vm.localidades = [];
            }
            var localidade = {
                pais: vm.localidade.pais.nome,
                estado: vm.localidade.estado ? vm.localidade.estado.nome : '',
                cidade: vm.localidade.cidade.nome,
                pessoa: {id: vm.pessoa.pessoaId}
            };
            Pessoa.saveLocalidade(localidade, function (data) {
                vm.localidades.push(localidade);
                vm.localidade = {pais: vm.brasil};
            });
        }

        function removerLocalidade(localidade, index) {
            Pessoa.removerLocalidade({id: localidade.id}, function (data) {
                vm.localidades.splice(index, 1);
            });
        }


        function salvarAtributosGenericos(atributos, tipo) {
            var agrupador = {vinculo: vm.pessoa.pessoaId, tipo: tipo, atributos: []};
            for (var i = 0; i < atributos.length; i++) {
                var atributo = atributos[i];
                agrupador.atributos.push({
                    atributo: atributo,
                    tipoVinculo: agrupador.tipo,
                    vinculo: agrupador.vinculo,
                    valor: atributo.tipoCampo === 'MULTIPLO' ? atributo.valor.join(',') : atributo.valor
                });

            }
            AtributoGenerico.saveVinculos(agrupador);
        }

        function salvarDadosPessoais() {
            var agrupador = {vinculo: vm.pessoa.pessoaId, tipo: 'DADOS_PESSOAIS', atributos: []};
            for (var i = 0; i < vm.atributosDadosPessoais.length; i++) {
                var atributo = vm.atributosDadosPessoais[i];
                agrupador.atributos.push({
                    atributo: atributo,
                    tipoVinculo: agrupador.tipo,
                    vinculo: agrupador.vinculo,
                    valor: atributo.tipoCampo === 'MULTIPLO' ? atributo.valor.join(',') : atributo.valor
                });

            }
            AtributoGenerico.saveVinculos(agrupador);
        }


    }
})();
