(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('perfil', {
            parent: 'home',
            url: '/perfil',
            data: {
                authorities: ['ROLE_USER', 'ROLE_ADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/perfil/perfil-list.html',
                    controller: 'PerfilListController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('home');
                    $translatePartialLoader.addPart('pessoa');
                    $translatePartialLoader.addPart('endereco');
                    $translatePartialLoader.addPart('formacaoAcademica');
                    $translatePartialLoader.addPart('remuneracao');
                    return $translate.refresh();
                }]
            }
        }).state('perfil.edit', {
            parent: 'perfil',
            url: '/perfil/edit',
            onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                var currentStateData = {
                    name: $state.current.name || 'home',
                    params: $state.params,
                    url: $state.href($state.current.name, $state.params)
                };

                $uibModal.open({
                    templateUrl: 'app/entities/perfil/perfil-edit.html',
                    controller: 'PerfilEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                }).result.then(function () {
                    $state.go(currentStateData.name, {id: currentStateData.params.id}, {reload: true});
                }, function () {
                    $state.go('^');
                });
            }]
        })

            .state('perfil.edit-status', {
                parent: 'perfil',
                url: '/perfil/edit-status',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };

                    $uibModal.open({
                        templateUrl: 'app/entities/perfil/perfil-edit-status.html',
                        controller: 'PerfilEditStatusController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    }).result.then(function () {
                        $state.go(currentStateData.name, {id: currentStateData.params.id}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('perfil.dialog-interesses', {
                parent: 'perfil',
                url: '/perfil/dialog-interesses',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };

                    $uibModal.open({
                        templateUrl: 'app/entities/perfil/perfil-dialog-interesses.html',
                        controller: 'PerfilDialogInteressesController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    }).result.then(function () {
                        $state.go(currentStateData.name, {id: currentStateData.params.id}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('perfil.dialog-remuneracao', {
                parent: 'perfil',
                url: '/perfil/dialog-remuneracao',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };

                    $uibModal.open({
                        templateUrl: 'app/entities/perfil/perfil-dialog-remuneracao.html',
                        controller: 'PerfilDialogRemuneracaoController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                    }).result.then(function () {
                        $state.go(currentStateData.name, {id: currentStateData.params.id}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('perfil.dialog-pcd', {
                parent: 'perfil',
                url: '/perfil/dialog-pcd',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };

                    $uibModal.open({
                        templateUrl: 'app/entities/perfil/perfil-dialog-pcd.html',
                        controller: 'PerfilDialogPcdController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    }).result.then(function () {
                        $state.go(currentStateData.name, {id: currentStateData.params.id}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })

            .state('perfil.dialog-dados-pessoais', {
                parent: 'perfil',
                url: '/perfil/dialog-dados-pessoais',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    var currentStateData = {
                        name: $state.current.name || 'home',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };

                    $uibModal.open({
                        templateUrl: 'app/entities/perfil/perfil-dialog-dados-pessoais.html',
                        controller: 'PerfilDialogDadosPessoaisController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                    }).result.then(function () {
                        $state.go(currentStateData.name, {id: currentStateData.params.id}, {reload: true});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
