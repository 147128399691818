(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('EmpregadorDashBoardController', EmpregadorDashBoardController);

    EmpregadorDashBoardController.$inject = ['$timeout', '$state', '$scope', '$stateParams',
        'entity', '$location', 'QueryService', '$uibModal'];

    function EmpregadorDashBoardController($timeout, $state, $scope, $stateParams,
        entity, $location, QueryService, $uibModal) {
        var vmds = this;

        vmds.entity = entity;
        vmds.idParceiro = entity.parceiro.id;

        vmds.contadoresPessoas = {};

        vmds.fim = new Date();
        vmds.inicio = new Date();
        vmds.inicio.setMonth(0);
        vmds.inicio.setDate(1);
        vmds.pageSizes = [10, 25, 50, 100];
        vmds.pageSize = vmds.pageSizes[0];

        vmds.chartOptions = {
            responsive: true,
            legend: {display: true, position: 'bottom', fullWidth: true},
        };
        vmds.quadroContadoresPessoas = [
            {
                title: 'Inscritos',
                atual: 'inscritos',
                anterior: 'inscritos_anteriores',
                footer: 'Todos Usuários da Base',
                goToDetail: goToQueryInscritos
            },
            {
                title: 'Disponíveis',
                atual: 'disponiveis',
                anterior: 'disponiveis_anteriores',
                footer: 'Disponível ou não informado',
                goToDetail: goToQueryDisponiveis
            },
            {
                title: 'Empresas', atual: 'empresas', anterior: 'empresas_anteriores', footer: 'Todas Empresas da Base',
                goToDetail: goToQueryEmpresas
            },
            {
                title: 'Vagas',
                atual: 'vagas',
                anterior: 'vagas_anteriores',
                footer: 'Apenas Vagas Abertas',
            },
        ];

        function buscarQuantidadePessoasVagas() {
            QueryService.execute({
                queryName: 'dashBoardIndicadoresQuantitativosPorParceiro',
                params: [
                    {name: 'idParceiro', value: Number(vmds.idParceiro)},
                    {name: 'inicio', value: toJSONLocal(vmds.inicio)},
                    {name: 'fim', value: toJSONLocal(vmds.fim)},
                ]
            }, function (data) {
                vmds.contadoresPessoas = data.data[0];
            });
        }

        function toJSONLocal(date) {
            var local = new Date(date);
            local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            return local.toJSON().slice(0, 10);
        }

        function buscarOfertaVagasAgrupadas(vagas) {
            QueryService.execute({
                queryName: 'dashBoardOfertaVagasPorParceiro',
                page: vagas.page - 1,
                size: vagas.pageSize,
                params: [
                    {name: 'idParceiro', value: Number(vmds.idParceiro)},
                    {name: 'inicio', value: toJSONLocal(vmds.inicio)},
                    {name: 'fim', value: toJSONLocal(vmds.fim)},
                ]
            }, function (data) {
                vmds.listaVagas[0] = {
                    data: data.data,
                    count: data.count,
                    page: vagas.page,
                    pageSize: vagas.pageSize,
                    title: 'Vagas Abertas',
                    reload: buscarOfertaVagasAgrupadas
                };
            });
        }

        function buscarProcuraVagasAgrupadas(vagas) {
            QueryService.execute({
                queryName: 'dashBoardProcuraVagasPorParceiro',
                page: vagas.page - 1,
                size: vagas.pageSize,
                params: [
                    {name: 'idParceiro', value: Number(vmds.idParceiro)},
                    {name: 'inicio', value: toJSONLocal(vmds.inicio)},
                    {name: 'fim', value: toJSONLocal(vmds.fim)},
                ]
            }, function (data) {
                vmds.listaVagas[1] = {
                    data: data.data,
                    count: data.count,
                    page: vagas.page,
                    pageSize: vagas.pageSize,
                    title: 'Procura por Vagas',
                    reload: buscarProcuraVagasAgrupadas
                };
            });
        }

        vmds.goToQuery = function (idVaga, title) {
            vmds.goToQueryDefault(
                'dashBoardOfertaParceirosPorVaga',
                [
                    {name: 'idParceiro', value: Number(vmds.idParceiro)},
                    {name: 'idVaga', value: Number(idVaga)},
                    {name: 'inicio', value: toJSONLocal(vmds.inicio)},
                    {name: 'fim', value: toJSONLocal(vmds.fim)}
                ],
                title,
                function (result) {
                    if (result && result.empregador_id)
                        window.open('/#/home/empregador/' + result.empregador_id, '_blank').focus()
                }, ''
            );
        };

        function goToQueryEmpresas() {
            vmds.goToQueryEmpresa('Empresas', ' ');
        }

        function goToQueryInscritos() {
            vmds.goToQueryPessoa('Inscritos', ' ');
        }

        function goToQueryDisponiveis() {
            vmds.goToQueryPessoa('Inscrirtos', ' and status.id = 990580');
        }

        vmds.goToQueryEmpresa = function (title, where) {
            vmds.goToQueryDefault(
                'dashBoardTodasEmpresasPorParceiro',
                [
                    {name: 'idParceiro', value: Number(vmds.idParceiro)},
                    {name: 'inicio', value: toJSONLocal(vmds.inicio)},
                    {name: 'fim', value: toJSONLocal(vmds.fim)}
                ],
                title,
                function (result) {
                    if (result && result.id)
                        window.open('/#/home/empregador/' + result.id, '_blank').focus();
                },
                where
            );
        };

        vmds.goToQueryPessoa = function (title, where) {
            vmds.goToQueryDefault(
                'dashBoardTodasPessoasPorParceiro',
                [
                    {name: 'idParceiro', value: Number(vmds.idParceiro)},
                    {name: 'inicio', value: toJSONLocal(vmds.inicio)},
                    {name: 'fim', value: toJSONLocal(vmds.fim)}
                ],
                title,
                function (result) {
                    if (result && result.id)
                        window.open('/#/perfil/' + result.id, '_blank').focus();
                },
                where
            );
        };

        vmds.goToQueryDefault = function (query, params, title, callback, where) {
            $uibModal.open({
                templateUrl: 'app/entities/dashboards/query-result.html',
                controller: 'QueryResultController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    query: function () {
                        return {
                            queryName: query,
                            where: where,
                            page: 0,
                            size: 20,
                            params: params
                        };
                    },
                    onClickRow: function () {
                        return function (result) {
                            callback(result);
                        };
                    },
                    title: function () {
                        return title;
                    },
                }
            });
        };


        function buscarEmpresaPorMes() {
            vmds.chartBarEmpresa = {};
            vmds.chartBarEmpresa.labels = [];
            vmds.chartBarEmpresa.series = ['Cadastro de Empresa'];
            vmds.chartBarEmpresa.data = [
                []
            ];

            QueryService.execute({
                queryName: 'dashBoardCadastroDeEmpresasPorMesPorParceiro',
                params: [
                    {name: 'idParceiro', value: Number(vmds.idParceiro)},
                    {name: 'inicio', value: toJSONLocal(vmds.inicio)},
                    {name: 'fim', value: toJSONLocal(vmds.fim)},
                ]
            }, function (data) {
                for (var i = 0; i < data.data.length; i++) {
                    var periodo = data.data[i];
                    var label = periodo.mes + '/' + periodo.ano;
                    if (vmds.chartBarEmpresa.labels.indexOf(label) < 0) {
                        vmds.chartBarEmpresa.labels.push(label);
                    }
                    vmds.chartBarEmpresa.data[0].push(periodo.count);
                }
            });
        }

        vmds.filtrar = function () {
            vmds.listaVagas = [
                {
                    data: [],
                    page: 1,
                    pageSize: vmds.pageSize,
                }, {
                    data: [],
                    page: 1,
                    pageSize: vmds.pageSize,
                },
            ];

            buscarQuantidadePessoasVagas();
            buscarOfertaVagasAgrupadas(vmds.listaVagas[0]);
            buscarProcuraVagasAgrupadas(vmds.listaVagas[1]);
            buscarEmpresaPorMes();
        };

        vmds.calcularPercentual = function (quadro) {
            return quadro.percentualCrescimento = ((vmds.contadoresPessoas[quadro.atual] - vmds.contadoresPessoas[quadro.anterior]) / vmds.contadoresPessoas[quadro.anterior]) * 100;
        };

        vmds.filtrar();

    }
})();
