(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('PessoaDetailController', PessoaDetailController);

    PessoaDetailController.$inject = ['$window', '$scope', '$rootScope', '$state', '$stateParams', 'previousState', 'entity', 'Pessoa',
        'FormacaoAcademica', 'ExperienciaProfissional', 'Mensagem', 'ConfirmService', 'Principal', 'Questionario', 'Vaga',
        'DocumentoPessoal', '$uibModal', 'Empregador', '$location', 'User'];

    function PessoaDetailController($window, $scope, $rootScope, $state, $stateParams, previousState, entity, Pessoa,
        FormacaoAcademica, ExperienciaProfissional, Mensagem, ConfirmService, Principal, Questionario, Vaga,
        DocumentoPessoal, $uibModal, Empregador, $location, User) {
        var vm = this;

        vm.enviarMensagem = enviarMensagem;
        vm.calculateAge = calculateAge;
        vm.abrirWhats = abrirWhats;
        vm.playAudio = playAudio;
        vm.playAudioFormacao = playAudioFormacao;
        vm.playAudioPerfil = playAudioPerfil;
        vm.playAudioExperiencia = playAudioExperiencia;
        vm.playVideo = playVideo;
        vm.trocarAba = trocarAba;
        vm.carregarVagas = carregarVagas;
        vm.consultarAvaliacoes = consultarAvaliacoes;
        vm.calculateDiff = calculateDiff;
        vm.baixarCurriculo = baixarCurriculo;
        vm.carregarInformacoesAvaliacoes = carregarInformacoesAvaliacoes;
        vm.selecionarCompetencia = selecionarCompetencia;
        vm.redefineSenhaIgualLogin = redefineSenhaIgualLogin;
        vm.top10 = top10;
        vm.top5 = top5;
        var host = $location.host();
        vm.subdomain = host.split('.')[0];

        vm.mostrarPerfil = true;
        vm.previousState = previousState.name;
        vm.labels = [];
        vm.series = ['Series A'];
        vm.data = [
            []
        ];

        vm.videosPefil = [
            {nome: 'Apresentação', campo: 'videoApresentacao'},
            {nome: 'Estrutura familiar', campo: 'videoFamiliar'},
            {nome: 'Competências e Habilidades', campo: 'videoHabilidades'},
            {nome: 'Capacidade de liderar pessoas', campo: 'videoLideranca'},
        ];
        vm.audiosPefil = [[
            {nome: 'Apresentação', campo: 'audioApresentacao'},
            {nome: 'Visão da Economia', campo: 'audioEconomia'},
            {nome: 'Visão da Educação', campo: 'audioEducacao'},
            {nome: 'Lazer e Diversão', campo: 'audioHobbies'},
            {nome: 'Expectativa de Remuneração', campo: 'audioRemuneracaoMensal'},
        ], [
            {nome: 'Capacitação Profissional', campo: 'audioCapacitacaoAtual'},
            {nome: 'Horários para Trabalhar', campo: 'audioHorariosTrabalho'},
            {nome: 'Como Gerar Resultados', campo: 'audioGerarResultados'},
            {nome: 'Tema Livre', campo: 'audioLivreRecrutador'},
        ]];

        vm.dadosPessoais = {tipo: 'DADOS_PESSOAIS', descricao: 'Sobre Você', atributos: []};
        vm.tipoAtributosDadosPessoais = [
            {tipo: 'DADOS_PESSOAIS_FAMILIA', descricao: 'Sobre seus Pais', atributos: []},
            {tipo: 'DADOS_PESSOAIS_CONJUGE', descricao: 'Sobre seu Cônjuge', atributos: []},
            {tipo: 'DADOS_PESSOAIS_FILHOS', descricao: 'Sobre seus Filhos', atributos: []},
        ];

        vm.pcd = {tipo: 'PCD', descricao: 'Pessoa com Deficiência', atributos: []};
        vm.atributosInteresse = {tipo: 'INTERESSES', descricao: 'Interesses', atributos: []};

        vm.baixarAnexo = baixarAnexo;

        vm.tabs = [
            {icon: "fa fa-user", hasAuthority: "ROLE_USER", name: "Apresentação", index: 0},
            // {icon:"fa fa-graduation-cap", name: "Formação e Carreira", index: 1},
            {icon: "fa fa-comments", hasAuthority: "ROLE_USER", name: "Interesses", index: 2},
            {
                icon: "fa fa-bar-chart",
                hasAuthority: "ROLE_USER",
                name: "Avaliações",
                index: 3,
                onClick: vm.carregarInformacoesAvaliacoes
            },
            // {icon:"fa fa-usd", name: "Remuneração", index: 4},
            // {icon:"fa fa-globe", name: "Planejamento de Cidades", index: 5},
            {icon: "fa fa-id-card-o", hasAuthority: "ROLE_USER", name: "Dados Pessoais", index: 6},
            {icon: "fa fa-wheelchair", hasAuthority: "ROLE_USER", name: "PCD", index: 7},
            {icon: "fa fa-group", hasAuthority: "ROLE_USER", name: "Contatos", index: 8},
            {
                icon: "fa fa-bookmark-o",
                name: "Vagas",
                index: 9,
                hasAuthority: "ROLE_ADMIN, ROLE_EMPREGADOR",
                onClick: vm.carregarVagas
            },
        ];
        vm.tabActive = 0;

        function calculateDiff(past, fut) {
            var date = new Date(past);
            var currentDate = fut ? new Date(fut) : new Date();
            var days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
            if (days > 365) {
                return Math.floor(days / 365) + " Anos";
            }
            if (days > 30) {
                return Math.floor(days / 30) + " Meses";
            }
            return days + " Dias";
        }

        function redefineSenhaIgualLogin(parceiro) {
            ConfirmService.confirm({
                title: "Atenção",
                text: "Deseja definir a senha desse usuário para que fique igual ao login?",
                confirm: function () {
                    for (var i = 0; i < vm.pessoa.emails.length; i++) {
                        var email = vm.pessoa.emails[i];

                        // Verifica se o email tá no formato correto e acessa a propriedade 'endereco'
                        if (email && email.endereco) {
                            email = email.endereco;  // Acesse a propriedade 'endereco' contém o e-mail
                        } else {
                            console.error("Erro: e-mail inválido ou não encontrado", email);
                            return;
                        }

                        console.log("Enviando requisição para redefinir senha para o login: ", email);

                        // Verifique a URL chamada
                        var url = 'api/redefine-senha-igual-login/' + encodeURIComponent(email);
                        console.log("URL chamada: ", url);

                        // Chama o serviço para redefinir a senha
                        User.redefineSenhaIgualLogin({ login: email },
                            function(response) {
                                console.log("Resposta da API:", response);  // Exibe a resposta completa
                                ConfirmService.swal({
                                    title: "Sucesso",
                                    text: "A senha foi redefinida com sucesso para o login.",
                                    type: "success",
                                    timer: 3000,
                                    showConfirmButton: false
                                });
                            },
                            function(error) {
                                console.error("Erro ao redefinir a senha:", error);  // Log do erro completo
                                ConfirmService.swal({
                                    title: "Erro",
                                    text: "Ocorreu um erro ao redefinir a senha. Tente novamente.",
                                    type: "error",
                                    timer: 3000,
                                    showConfirmButton: false
                                });
                            });
                    }
                },
                cancel: function () {
                    // Cancelamento da redefinição de senha
                    console.log("Redefinição de senha cancelada.");
                }
            });
        }






        function carregarInformacoes() {
            vm.pessoa = entity;
            FormacaoAcademica.getAllByUserId({id: vm.pessoa.user.id}, function (formacoes) {
                console.log("Dados retornados:", formacoes);
                vm.formacoes = [];
                vm.cursos = [];

                if (formacoes && formacoes.length > 0) {
                    angular.forEach(formacoes, function (item) {

                        console.log("Estrutura completa do item:", JSON.stringify(item, null, 2));


                        if (item.tipoFormacao) {
                            if (item.tipoFormacao === "Curso") {
                                console.log("Curso encontrado:", item);  // Log para verificar que é um curso
                                vm.cursos.push(item);
                            } else {

                                console.log("Tratando como formação acadêmica:", item);  // Log para verificar que é uma formação
                                vm.formacoes.push(item);
                            }
                        } else {
                            // Caso o campo 'tipoFormacao' não exista ou esteja vazio, trata como formação acadêmica
                            console.log("Tratando como formação acadêmica (sem campo 'tipoFormacao'):", item);
                            vm.formacoes.push(item);
                        }
                    });
                } else {
                    console.log("Nenhuma formação encontrada.");
                }
            });

            // Carregar documentos pessoais
            DocumentoPessoal.getAllByUserId({id: vm.pessoa.user.id}, function (documentos) {
                vm.documentos = documentos;
            });

            // Carregar experiências profissionais
            ExperienciaProfissional.getAllByUserId({id: vm.pessoa.user.id}, function (experiencias) {
                vm.experiencias = experiencias;
                angular.forEach(vm.experiencias, function (experiencia) {
                    var str = experiencia.empregador ? experiencia.empregador.nomeFantasia : experiencia.cargo;
                    vm.labels.push((str.length > 20) ? str.slice(0, 19) + '...' : str);
                    var timeDiff = Math.abs(new Date(experiencia.inicio).getTime() - (experiencia.fim ? new Date(experiencia.fim).getTime() : new Date()));
                    vm.data[0].push(Math.ceil(timeDiff / (1000 * 3600 * 24) / 30));
                });
            });

            // Carregar localidades
            Pessoa.getLocalidades({id: vm.pessoa.id}, function (localidades) {
                vm.localidades = localidades;
            });
        }


        function calculateAge(birthday) {
            if (!birthday) {
                return;
            }
            return new Date(new Date() - new Date(birthday)).getFullYear() - 1970;
        }

        function baixarAnexo(arquivoId) {
            Pessoa.baixarAnexo(arquivoId);
        }

        function enviarMensagem() {
            Mensagem.save(vm.mensagem, function (mensagem) {
                $state.go("mensagem");
                ConfirmService.swal({
                    title: "Sua mensagem foi enviada!",
                    text: "Aguarde a resposta de " + vm.pessoa.nome,
                    timer: 3000,
                    showConfirmButton: false
                });
            });
            inicarMensagem();
        }


        function inicarMensagem() {
            vm.mensagem = {texto: "", destino: vm.pessoa.user, publicadoEm: new Date()};
        }


        function carregarInformacoesAvaliacoes() {

            vm.todasCompetencias = [];

            vm.avaliacaoGeral = {apuracao: {}};

            Questionario.getResultadoInternoCorporateAvaliacaoByPessoaId({id: vm.pessoa.id}, function (sucesso) {
                console.log("SUCESSO AVALIACAO: ", sucesso);
                vm.avaliacoes = sucesso;
                for (var i = 0; i < vm.avaliacoes.length; i++) {
                    var avaliacao = vm.avaliacoes[i];
                    avaliacao.questionarios = avaliacao.etapas.filter(function (e) {
                        return e.tiposhort === 'Q';
                    });
                    avaliacao.entrevistas = avaliacao.etapas.filter(function (e) {
                        return e.tiposhort === 'E';
                    });
                    if (avaliacao.etapas_realizadas && avaliacao.etapas_realizadas > 0) {
                        carregarApuracao(avaliacao);
                    }
                }


            }, function (erro) {
                console.log("ERRO: ", erro);
            });


        }

        function carregarApuracao(avaliacao) {
            Questionario.getResultadoApuracao({avaliacaoId: avaliacao.id}, function (sucesso) {
                console.log("SUCESSO AVALIACAO: ", sucesso);
                avaliacao.apuracao = sucesso;
                init(avaliacao.apuracao);
            }, function (erro) {
                console.log("ERRO: ", erro);
            });
        }

        function zerarRadar(apuracao) {
            apuracao.radarCorporate = {
                labels: [],
                data: [
                    [],
                    [],
                    []
                ],
                colours: [
                    {
                        backgroundColor: 'rgba(255,51,0,0.5)',
                        borderColor: 'rgba(255,51,0,0.5)',
                        pointBackgroundColor: 'rgba(255,51,0,0.5)',
                        pointHoverBorderColor: 'rgba(255,51,0,0.5)'
                    },
                    {
                        backgroundColor: 'rgba(0,0,0,0)',
                        borderColor: 'rgba(0,102,255,0.5)',
                        pointBackgroundColor: 'rgba(0,102,255,0.5)',
                        pointHoverBorderColor: 'rgba(0,102,255,0.5)'
                    },
                    {
                        backgroundColor: 'rgba(0,0,0,0)',
                        borderColor: 'rgba(0,0,0,0)',
                        pointBackgroundColor: 'rgba(0,0,0,0)',
                        pointHoverBorderColor: 'rgba(0,0,0,0)'
                    }
                ]
            };
        }

        function carregarRadarDasCompetenciasSelecionadas() {
            for (var i = 0; i < vm.topCompetencias.length; i++) {
                var competencia = vm.topCompetencias[i];
                vm.avaliacaoGeral.apuracao.radarCorporate.labels.push(competencia.nome + " (" + competencia.valor + "%)");
                vm.avaliacaoGeral.apuracao.radarCorporate.data[0].push(competencia.valor);
                vm.avaliacaoGeral.apuracao.radarCorporate.data[1].push(100);
                vm.avaliacaoGeral.apuracao.radarCorporate.data[2].push(1);
            }
        }

        function init(apuracao) {
            zerarRadar(apuracao);
            for (var i = 0; i < apuracao.competencias.length; i++) {
                var competencia = apuracao.competencias[i];
                competencia.valor = Math.round(competencia.valor);
                apuracao.radarCorporate.labels.push(competencia.nome);
                apuracao.radarCorporate.data[0].push(competencia.valor);
                apuracao.radarCorporate.data[1].push(100);
                apuracao.radarCorporate.data[2].push(1);
                var jaTem = false;
                for (var j = 0; j < vm.todasCompetencias.length; j++) {
                    var comp = vm.todasCompetencias[j];
                    if (comp.nome == competencia.nome) {
                        jaTem = true;
                    }
                }
                if (!jaTem)
                    vm.todasCompetencias.push(competencia);
            }

            vm.todasCompetencias.sort(function (a, b) {
                if (b.nome > a.nome) {
                    return 1;
                }
                if (b.nome < a.nome) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });

            top10();
            vm.mostrarAvaliacoes = true;
        }

        function top10() {
            zerarRadar(vm.avaliacaoGeral.apuracao);
            vm.topCompetencias = vm.todasCompetencias.slice(0, 10);
            carregarRadarDasCompetenciasSelecionadas();
        }

        function top5() {
            zerarRadar(vm.avaliacaoGeral.apuracao);
            vm.topCompetencias = vm.todasCompetencias.slice(0, 5);
            carregarRadarDasCompetenciasSelecionadas();
        }

        function selecionarCompetencia(competencia) {
            if (vm.topCompetencias.indexOf(competencia) >= 0) {
                vm.topCompetencias.splice(vm.topCompetencias.indexOf(competencia), 1);
            } else {
                vm.topCompetencias.push(competencia);
            }
            zerarRadar(vm.avaliacaoGeral.apuracao);
            carregarRadarDasCompetenciasSelecionadas();
        }


        function carregarVagas() {
            vm.vagas = Vaga.getVagasPessoa({id: vm.pessoa.id});
        }

        function baixarCurriculo() {

            Pessoa.imprimirCompleto(vm.pessoa.id);

            // Pessoa.getCurriculo({id: vm.pessoa.id}, function (data) {
            //     /*  console.log(data);
            //       var newWindow = window.open("", "mywindow", "menubar=1,resizable=1,width=500,height=500");
            //       newWindow.document.write(data);*/
            //
            //     console.log("data ", data);
            //     vm.arquivo = data;
            //
            //     var fileName = vm.arquivo.nome;
            //     var a = document.createElement("a");
            //     document.body.appendChild(a);
            //     a.style = "display: none";
            //
            //     var file = getBlobFromBase64(vm.arquivo);
            //     var fileURL = window.URL.createObjectURL(file);
            //     a.href = fileURL;
            //     a.download = fileName + ".pdf";
            //     a.click();
            //
            // });
        }

        function getBlobFromBase64(arquivo) {
            /*data:{{vm.arquivo.contentType}};base64,{{vm.arquivo.bytes}}*/
            var byteCharacters = atob(arquivo.bytes);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {type: 'application/pdf'});
            return blob;
        }

        function trocarAba() {
            if (vm.mostrarPerfil) {
                vm.mostrarPerfil = false;
                carregarInformacoesAvaliacoes();
                carregarVagas();
            } else {
                vm.mostrarPerfil = true;
                vm.mostrarAvaliacoes = false;
            }
        }

        function abrirWhats(telefone) {
            $window.open(" https://wa.me/" + (telefone.numero.startsWith('55') ? telefone.numero : '55' + telefone.numero), "_blank");
        }

        function playAudioPerfil(campoAudio) {
            playAudio('audio', vm.pessoa.perfil[campoAudio]);
        }

        function playAudioFormacao(formacao) {
            playAudio('audio', formacao.audio);
        }

        function playAudioExperiencia(experiencia) {
            playAudio('audio', experiencia.audioApresentacao);
        }

        function playAudio(idDocument, audio) {
            $uibModal.open({
                templateUrl: 'app/entities/audio/audio-detail.html',
                controller: 'AudioDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    audio: function () {
                        return audio;
                    }
                }
            });
        }

        function playVideo(tipo) {
            $uibModal.open({
                templateUrl: 'app/entities/video/video-detail.html',
                controller: 'VideoDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    video: function () {
                        return vm.pessoa.perfil[tipo];
                    }
                }
            });
        }

        function consultarAvaliacoes() {
            $uibModal.open({
                templateUrl: 'app/entities/questionario/questionario-pessoa.html',
                controller: 'QuestionarioPessoaController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    pessoaId: ['PlanoCredito', function (PlanoCredito) {
                        //return User.get({login: $stateParams.login});
                        return vm.pessoa.id;
                    }]
                }
            });
        }


        Principal.identity().then(function (account) {
            vm.isAuthenticated = Principal.isAuthenticated();
            if (vm.isAuthenticated) {
                Principal.hasAuthority("ROLE_ADMIN").then(function (result) {
                    if (result) {
                        carregarInformacoes();
                        inicarMensagem();
                    } else {
                        Principal.hasAuthority("ROLE_EMPREGADOR").then(function (result) {
                            if (result) {
                                Empregador.temPerfil({idPessoa: entity.id}, function (result) {
                                    if (result && result.id) {
                                        carregarInformacoes();
                                        inicarMensagem();
                                    } else {
                                        $state.go("pessoa-detail", {id: entity.id});
                                    }
                                });
                            } else {
                                $state.go("pessoa-detail", {id: entity.id});
                            }
                        });

                    }
                });
            }
        });


    }
})();
