(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$timeout', 'Principal', 'LoginService', '$state', 'Pessoa', 'Publicacao', 'Questionario', 'Vaga', '$sce', 'Produto', 'Empregador', '$location', '$cookies', 'Parceiro'];

    function HomeController($scope, $timeout, Principal, LoginService, $state, Pessoa, Publicacao, Questionario, Vaga, $sce, Produto, Empregador, $location, $cookies, Parceiro) {
        var vm = this;
        vm.admin = null;
        vm.isEditing = false;
        vm.datePickerOpenStatus = {};
        vm.format_date = 'dd/MM/yyyy';
        vm.clear = clear;
        vm.publicar = publicar;
        vm.removerPublicacao = removerPublicacao;
        vm.curtir = curtir;
        vm.baixarCurriculo = baixarCurriculo;
        vm.carregarInformacoesAvaliacoes = carregarInformacoesAvaliacoes;
        vm.carregarMaisPublicacoes = carregarMaisPublicacoes;
        vm.trustedHtml = trustedHtml;
        vm.fecharVideo = fecharVideo;
        vm.percentualCadastro = null;
        vm.arquivo = {};
        vm.page = 0;
        vm.publicacoes = [];
        vm.itemsPerPage = 60;
        vm.vagasLoaded = false;
        vm.isAuthenticated = false;
        vm.mostraPagina = false;
        vm.video =  $sce.trustAsResourceUrl('https://www.youtube.com/embed/Rdj85VF2uYI');
        vm.mostrarVideo = $cookies.getObject("mostrarVideo") == false ? false : true;
        var host = $location.host();
        vm.subdomain = host.split('.')[0];


        vm.etapasEntrevista = [
            {titulo: 'VÍDEO ENTREVISTA', imagem: 'content/images/etapa_entrevista.jpg'},
            {titulo: 'AUDIO ENTREVISTA', imagem: 'content/images/etapa_audio.jpg'},
            {titulo: 'CURRÍCULO ONLINE', imagem: 'content/images/etapa_curriculo.jpg'},
        ];

        vm.possibilidadesPersonal = [
            {
                numero: '01',
                titulo: 'Trajetória Profissional',
                texto: 'No Perfil MySkills você pode fornecer informações da sua trajetória profissional com bastantes detalhes'
            },
            {
                numero: '02',
                titulo: 'Interesse Profissão',
                texto: 'No Perfil MySkills você pode marca as profissões e oportunidades do seu interesse'
            },
            {
                numero: '03',
                titulo: 'Interesse Cidade',
                texto: 'No Perfil MySkills você pode marca as cidades que você tem interesse em trabalhar'
            },
            {
                numero: '04',
                titulo: 'Habilidades Técnicas',
                texto: 'No Perfil MySkills você pode fazer avaliações que irão mensurar a suas habilidades técnicas e comportamentais'
            },
        ];

        vm.etapasCurriculo = [
            {numero: '01', titulo: 'GRAVE', texto: 'Áudio e Vídeo entrevista de forma estruturada'},
            {numero: '02', titulo: 'MARQUE', texto: 'As profissões, cidades e empresas que deseja trabalhar'},
            {numero: '03', titulo: 'AGUARDE', texto: 'O contato das empresas direto em seu WhatsApp'},
        ];

        vm.slickConfig = {
            enabled: true,
            autoplay: true,
            draggable: false,
            dots: true,
            arrows: false,
            autoplaySpeed: 7000,
            method: {},
            responsive: [{
                breakpoint: 1200, settings: {
                    slidesToShow: 2, slidesToScroll: 1, dots: true, arrows: false
                }
            }, {
                breakpoint: 1008, settings: {
                    slidesToShow: 1, slidesToScroll: 1, dots: true, arrows: false
                }
            }

            ]
        };

        vm.slides = [{
            id: 0,
            image: '/content/images/douglas.jpg',
            url: 'https://www.talentbank.com.br.a2ead.com.br/curso/people-analytics-e-engenharia-de-cargos'
        }, {
            id: 1, image: '/content/images/speed1.jpg', url: 'https://gr.eduk.com.br/talentbank'
        },


        ];


        $scope.goToOpcao = function (opcao) {

            if (opcao.src) {
                // var win = window.open(opcao.src,  'about:blank', 'noopener');
                // win.focus();

                var link = document.createElement('a');
                link.setAttribute('rel', 'noopener noreferrer');
                link.setAttribute('target', 'blank');
                link.setAttribute('href', opcao.src);
                link.click();

            }
        };

        $scope.gotoLogin = function () {
            $state.go('login');
        };
        $scope.goToPerfil = function (id) {
            if (Principal.isAuthenticated()) {
                $state.go('perfil', {id: id});
            } else {
                $state.go('login');
            }
        };

        Principal.hasAuthority("ROLE_ADMIN")
            .then(function (result) {
                if (result) {
                    vm.admin = true;
                } else {
                    vm.admin = false;
                }
            });


        function getBlobFromBase64(arquivo) {
            /*data:{{vm.arquivo.contentType}};base64,{{vm.arquivo.bytes}}*/
            var byteCharacters = atob(arquivo.bytes);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {type: 'application/pdf'});
            return blob;
        }

        function trustedHtml(html) {
            return $sce.trustAsHtml(html);
        }

        function baixarCurriculo() {


            Pessoa.getCurriculo({id: vm.pessoa.pessoaId}, function (data) {
                /*  console.log(data);
                  var newWindow = window.open("", "mywindow", "menubar=1,resizable=1,width=500,height=500");
                  newWindow.document.write(data);*/

                console.log("data ", data);
                vm.arquivo = data;

                var fileName = vm.arquivo.nome;
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";

                var file = getBlobFromBase64(vm.arquivo);
                var fileURL = window.URL.createObjectURL(file);
                a.href = fileURL;
                a.download = fileName + ".pdf";
                a.click();

            });
        }


        function buscarPercentualCadastro(id) {
            Pessoa.getPercenutalConcluido({id: id}, findByPercentualCadastroSuccess);
        }

        function findByPercentualCadastroSuccess(percentualCadastro) {
            vm.percentualCadastro = percentualCadastro;
        }

        function carregarPublicacoes() {
            Publicacao.query({
                page: vm.page, size: vm.itemsPerPage,
            }, function (publicacoes) {
                for (var i = 0; i < publicacoes.length; i++) {
                    var pub = publicacoes[i];
                    var contem = false;
                    for (var j = 0; j < vm.publicacoes.length; j++) {
                        var pub2 = vm.publicacoes[j];
                        if (pub2.pessoa.pessoaId === pub.pessoa.pessoaId && pub2.texto === pub.texto) {
                            contem = true;
                            break;
                        }
                    }
                    if (!contem) {
                        vm.publicacoes.push(pub);
                    }
                }
            });

            function sort() {
                var result = [];
                result.push('publicadoEm' + ',' + 'desc');
                return result;
            }
        }

        function carregarMaisPublicacoes() {
            vm.page = vm.page + 1;
            carregarPublicacoes();
        }

        carregarPublicacoes();

        function clear() {
            vm.novaPublicacao = "";
        }

        function publicar() {
            Publicacao.save({
                texto: vm.novaPublicacao, pessoa: {id: vm.pessoa.pessoaId}, publicadoEm: new Date()
            }, function (data) {
                clear();
                vm.publicacoes = [];
                carregarPublicacoes();
            });

        }

        function removerPublicacao(id) {
            Publicacao.delete({id: id}, function () {
                vm.publicacoes = [];
                carregarPublicacoes();
            });

        }

        function curtir(index) {
            Publicacao.curtir({
                id: vm.publicacoes[index].id
            }, function (data) {
                vm.publicacoes[index] = data;
            });

        }

        function carregarInformacoesAvaliacoes() {
            vm.mostrarNovaAvaliacao = true;
            Questionario.getResultadoInternoCorporateAvaliacao({}, function (sucesso) {
                vm.avaliacoes = sucesso;
                if (sucesso.length > 0) {
                    vm.mostrarNovaAvaliacao = false;
                }
                var naoFinalizadas = sucesso.filter(function (a) {
                    return a.status !== 'FINALIZADA';
                });
                if (naoFinalizadas.length > 0) {
                    vm.mostrarNovaAvaliacao = false;
                    vm.mostrarAvaliacoes = true;
                }
            }, function (erro) {
                console.log("ERRO: ", erro);
            });
        }

        function fecharVideo() {
            $cookies.putObject("mostrarVideo", false);
            vm.mostrarVideo = false;
        }

        function carregarParceiro() {
            Parceiro.getPublico({identificador: vm.subdomain}, function (data) {
                vm.parceiro = data;
                vm.idParceiro = vm.parceiro.id;
                vm.mostrarVideo = vm.parceiro.mostrarVideoExplicacao;

                vm.video =
                    $sce.trustAsResourceUrl(vm.parceiro && vm.parceiro.linkVideo ? vm.parceiro.linkVideo : 'https://www.youtube.com/embed/Rdj85VF2uYI');


                montarQuadrosRodape();
                carregarEmpregadores();
            }, function (erro) {
                montarQuadrosRodape();
                carregarEmpregadores();
            });
        }

        function montarQuadrosRodape() {
            vm.opcoesPublicacoes = [{
                id: 0,
                title: 'Contato Comercial',
                icon: '/content/images/icons/blog.png',
                src: vm.parceiro != null && vm.parceiro.linkContatoComercial ? vm.parceiro.linkContatoComercial : 'https://www.talentbank.com.br/contato/'
            }, {
                id: 1,
                title: 'Downloads',
                icon: '/content/images/icons/downloads.png',
                src: 'https://myskills-anexos.s3.amazonaws.com/MySkills.pdf'
            }, {
                id: 2,
                title: 'Perfil Tutorial',
                icon: '/content/images/icons/videos.png',
                src: 'https://personal.myskills.com.br/#/perfil/1673012'
            }, {
                id: 3,
                title: 'Depoimentos',
                icon: '/content/images/icons/depoimentos.png',
                src: vm.parceiro != null && vm.parceiro.linkDepoimentos ? vm.parceiro.linkDepoimentos : ''
            }, {
                id: 4,
                title: 'Talentos',
                icon: '/content/images/icons/novidades.png',
                src: 'https://www.talentbank.com.br/talento/'
            }, {
                id: 5,
                title: 'Cursos à Distância',
                icon: '/content/images/icons/cursos.png',
                src: vm.parceiro != null && vm.parceiro.linkEAD ? vm.parceiro.linkEAD : ''
            }];
        }

        function carregarEmpregadores() {
            var filtro = {page: 0, pageSize: 10};
            if (vm.parceiro) {
                filtro.parceiroId = vm.parceiro.id;
            }
            Empregador.filtrar(filtro, function (data, headers) {
                vm.empregadores = data;
            });

            Empregador.count({parceiroId: vm.idParceiro ? vm.idParceiro : null}, function (data) {
                if (data && data.quantidade) {
                    vm.totalEmpregadores = data.quantidade;
                    vm.totalVagas = data.vagas;
                }
            });
            Pessoa.count({parceiroId: vm.idParceiro ? vm.idParceiro : null}, function (data) {
                if (data && data.quantidade) {
                    vm.totalPessoas = data.quantidade;
                }
            });

        }

        function carregarVagas() {
            vm.vagasLoaded = false;
            Vaga.getAll({
                page: 0, size: 10,
            }, function (data) {
                vm.vagas = data;
                vm.vagasLoaded = true;
            });
        }


        function carregarProdutos() {
            vm.produtosLoaded = false;
            Produto.query({
                page: 0, size: 10,
            }, function (data) {
                vm.produtos = data;
                vm.produtosLoaded = true;
            });
        }

        getAccount();


        function getAccount() {

            $timeout(function () {
                window.onbeforeunload = function () {
                    window.scrollTo(0, 0);
                };
            });

            $timeout(function () {
                vm.mostraPagina = true;
            }, 2000);


            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated();
                if (vm.account) {

                    Principal.hasAuthority("ROLE_EMPREGADOR")
                        .then(function (result) {
                            if (result) {
                                $state.go('empregador');
                            }
                        });

                    Principal.hasAuthority("ROLE_PESSOA")
                        .then(function (result) {
                            if (result) {
                                $state.go('perfil');
                            }
                        });
                    Pessoa.getPerfilByUser({}, function (pessoa) {
                        vm.pessoa = pessoa;
                        vm.pessoa.nascimento_tela = pessoa.nascimento;
                        buscarPercentualCadastro(vm.pessoa.pessoaId);
                        carregarInformacoesAvaliacoes();

                    });


                    Vaga.count({}, function (data) {
                        if (data && data.quantidade) {
                            vm.totalVagas = data.quantidade;
                        }
                    });
                }
            });
        }

        function openFbPopUp() {
            var fburl = 'rb.gy/qlptq7';
            var fbimgurl = 'https://s3.amazonaws.com/myskills-corporate-scarpini/users/9c641fd5-e2c9-4ca3-b0f6-09331adcea01_crop';
            var fbtitle = 'Your title';
            var fbsummary = "teste description";
            var sharerURL = "http://www.facebook.com/sharer/sharer.php?s=100&p[url]=" + encodeURI(fburl) + "&p[images][0]=" + encodeURI(fbimgurl) + "&p[title]=" + encodeURI(fbtitle) + "&p[summary]=" + encodeURI(fbsummary);
            window.open(sharerURL, 'facebook-share-dialog', 'width=626,height=436');
            return false;
        }

        vm.openFbPopUp = openFbPopUp;

        carregarVagas();
        carregarProdutos();

        carregarParceiro();


    }
})();
